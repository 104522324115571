import React, { useState } from 'react';
import { Container, Text, Flex, Grid, Stack, useBreakpoint, Select } from 'basis';
import { LayoutContent, AccordionAtXS, StyledUL, MoneyDocumentsIcon } from 'components';
import { DocUpload } from 'doc-upload';
import { byCountry, isAU } from 'utils';
import { employmentStatusOptionsAU, employmentStatusOptionsNZ, UNEMPLOYED } from '_config';
import { generateUploadConfig } from './uploadConfig';

import { content } from './content';

export function UploadOtherDocuments({ applicationRef, employmentStatus, channel, handleDocUploadEvent }) {
  const breakpoint = useBreakpoint();
  const [documentType, setDocumentType] = useState(null);
  const config = generateUploadConfig({
    applicationRef,
    channel,
    documentSubType: 'OTHER',
    handleDocUploadEvent,
  });
  const contentByCountry = byCountry(content);
  const employmentStatusOption = isAU() ? employmentStatusOptionsAU : employmentStatusOptionsNZ;
  const employmentStatusLabel = employmentStatusOption.find(option => option.value === employmentStatus)?.label;
  const employmentStatusContent = contentByCountry.otherDocuments[employmentStatus];
  const showEmploymentTypeTitle = breakpoint !== 'xs';

  const keys = c => {
    return Object.keys(c).reduce((a, v) => [...a, { value: v, label: v }], []);
  };

  return (
    <LayoutContent hasBreakpointWidth={false}>
      <Flex padding="0 0 6 0">
        <>
          <MoneyDocumentsIcon width={49} height={61} />
          <Text margin="0 0 0 6">Bank statements and other documents will take 1-2 business days to review.</Text>
        </>
      </Flex>
      <Container padding="0" margin="2 0 0 0">
        {!showEmploymentTypeTitle && (
          <Text margin="0 0 2 0" textStyle="heading6">
            What documents do I need?
          </Text>
        )}
        <AccordionAtXS label={employmentStatusLabel}>
          <Stack gap="2">
            <Grid preset="page" rowsGap={6}>
              {showEmploymentTypeTitle && employmentStatusLabel && employmentStatus !== UNEMPLOYED && (
                <Grid.Item colSpan="all">
                  <Text textStyle="heading5">Employment type: {employmentStatusLabel}</Text>
                </Grid.Item>
              )}

              <Grid.Item colSpan="all">
                <Text>
                  <strong>
                    Please provide any of the below documents. <br />
                    All documents must contain your full legal name.
                  </strong>
                </Text>
              </Grid.Item>
              <Grid.Item colSpan="all">
                <Container padding="0 0 2 0">
                  {employmentStatusContent &&
                    employmentStatusContent.map((item, index) => {
                      return (
                        <React.Fragment key={`emp-status-content-${index}`}>
                          <Text margin="2 0 2 0" textStyle="heading6">
                            {item.title}
                          </Text>
                          {item.list?.length > 0 && (
                            <StyledUL>
                              {item.list.map((listItem, listIndex) => {
                                return (
                                  <li key={`emp-status-content-list-${index}-${listIndex}`}>
                                    <Text>{listItem}</Text>
                                  </li>
                                );
                              })}
                            </StyledUL>
                          )}
                        </React.Fragment>
                      );
                    })}
                  {!employmentStatusContent && (
                    <Select
                      name="incomeEmploymentType"
                      label="Select your document type "
                      options={keys(contentByCountry.otherDocuments.default)}
                      testId="incomeEmploymentType"
                      placeholder="Please select the document type"
                      onChange={e => setDocumentType(contentByCountry.otherDocuments.default[e.selectedOption.value])}
                    />
                  )}
                  {!employmentStatusContent &&
                    documentType?.map((item, index) => {
                      return (
                        <React.Fragment key={`emp-status-content-${index}`}>
                          <Text margin="6 0 2 0" textStyle="heading6">
                            {item.title}
                          </Text>
                          {item.list?.length > 0 && (
                            <StyledUL>
                              {item.list.map((listItem, listIndex) => {
                                return (
                                  <li key={`emp-status-content-list-${index}-${listIndex}`}>
                                    <Text>{listItem}</Text>
                                  </li>
                                );
                              })}
                            </StyledUL>
                          )}
                        </React.Fragment>
                      );
                    })}
                </Container>
                <Container margin="4 0 0 0">{contentByCountry.payslips.acceptableDocs}</Container>
              </Grid.Item>
            </Grid>
          </Stack>
        </AccordionAtXS>
        <Container margin="8 0 0 0">
          <DocUpload {...config} />
        </Container>
      </Container>
    </LayoutContent>
  );
}
